import React, { Component } from "react";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import Pagination from "../../HelperComponents/Pagination/Pagination";
import "./OrderItems.scss";
import NoItems from "../../../assets/image/no_orders_3.png";
import { connect } from "react-redux";
import { getMyPurchases } from "../../../actions/PurchasesActions";
import OrderItem from "./OrderItem/OrderItem";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "../../../assets/image/search_icon.svg";
import FormControl from "@material-ui/core/FormControl";
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";
import { isArray } from "../../../helpers/functions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class OrderItems extends Component {
    state = {
        activePage: 1,
        hasResults: false,
        items: [],
        items_count: 0,
        search: "",
        status: null,
        fromDate: null,
        toDate: null,
        statusList: [
            {
                label: "В работе",
                value: "processing",
            },
            {
                label: "В пути",
                value: "on_way",
            },
            {
                label: "Подтверждено",
                value: "confirmed",
            },             
            {
                label: "Выполнен",
                value: "complete",
            },
            {
                label: "Отказ",
                value: "denied",
            },
            {
              label: "Ожидаем документы",
              value: "waiting_for_documents",
            },
            {
              label: "Ожидаем деталь",
              value: "waiting_for_detail",
            },
        ],
    };

    changePage = (pageNumber) => {
        this.setState({ activePage: pageNumber });
        this.getMyPurchases(pageNumber);
    };

    componentDidMount() {
        let page = this.state.activePage;

        this.getMyPurchases(page);
    }

    getMyPurchases = (page) => {
        let arr = [];
        const { getMyPurchases } = this.props;
        const { search, status, fromDate, toDate } = this.state;

        arr.push(`page=${page}`);
        arr.push(`page_size=100`);
        search && arr.push(`search=${search}`);
        status && arr.push(`status=${status.value}`);
        fromDate &&
            arr.push(
                `from_date=${moment(fromDate).format(
                    "YYYY-MM-DDTHH:MM:ss.SSS"
                )}Z`
            );
        toDate &&
            arr.push(
                `to_date=${moment(toDate).format("YYYY-MM-DD")}T23:59:00.000Z`
            );

        getMyPurchases(arr).then((res) => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                if (res.payload.data.results.length === 0) {
                    this.setState({ hasResults: false });
                } else {
                    this.setState({
                        hasResults: true,
                    });
                }
            }
        });
    };

    handleSearch = (event) => {
        const { activePage } = this.state;
        if (event.keyCode === 13) {
            this.setState({ activePage: 1 });
            this.getMyPurchases(activePage);
        }
    };

    handleDateChange = (date, id) => {
        const { activePage } = this.state;
        let obj = {};
        obj[`${id}`] = date;
        // this.setState(obj);
        this.setState(obj, () => {
            this.getMyPurchases(activePage);
        });
        // this.getMyPurchases(activePage);
    };

    months = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
    ];
    days = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
    locale = {
        localize: {
            month: (n) => this.months[n],
            day: (n) => this.days[n],
        },
        formatLong: {},
    };

    render() {
        const {
            activePage,
            items_count,
            hasResults,
            items,
            search,
            status,
            statusList,
            fromDate,
            toDate,
        } = this.state;
        const { data } = this.props;

        return (
            <TransitionedBlock>
                <div className="filter-wrapper">
                    <div className="search_wrapper search_wrapper_small">
                        <InputBase
                            placeholder="Поиск…"
                            onKeyDown={this.handleSearch}
                            onChange={(e) => {
                                this.setState({ search: e.target.value });
                            }}
                            value={search}
                            classes={{
                                input: "search_input",
                            }}
                        />
                        <div className="search_input_icon">
                            <img src={SearchIcon} alt="SearchIcon" />
                        </div>
                    </div>
                    <FormControl className="select_wrapper">
                        <SelectComponent
                            value={status}
                            options={statusList}
                            loading={!isArray(statusList)}
                            change={(e) =>
                                this.setState({ status: e }, () => {
                                    this.getMyPurchases(activePage);
                                })
                            }
                            placeholder="Статусы"
                            isSearchable={false}
                            isClearable={false}
                        />
                    </FormControl>
                    <div className="date_picker_wrapper">
                        <div className="date_picker_inner">
                            <p>От</p>
                            <DatePicker
                                selected={fromDate}
                                selectsStart
                                startDate={fromDate}
                                endDate={toDate}
                                dateFormat="dd.MM.yyyy"
                                onChange={(e) =>
                                    this.handleDateChange(e, "fromDate")
                                }
                                className="myDatePicker"
                                locale={this.locale}
                                isClearable
                            />
                        </div>
                        <div className="date_picker_inner">
                            <p>До</p>
                            <DatePicker
                                selected={toDate}
                                selectsEnd
                                startDate={fromDate}
                                endDate={toDate}
                                dateFormat="dd.MM.yyyy"
                                minDate={fromDate}
                                onChange={(e) =>
                                    this.handleDateChange(e, "toDate")
                                }
                                className="myDatePicker"
                                locale={this.locale}
                                isClearable
                            />
                        </div>
                    </div>
                </div>
                <div className="order_item">
                    {hasResults ? (
                        <div className="my_orders_table">
                            <div className="transactions_columns table_container">
                                <div className="table_header">
                                    <div className="table_row">
                                        <div className="row_orders">
                                            <div className="row_item">
                                                Дата/время
                                            </div>
                                            <div className="row_item" />
                                            <div className="row_item" />
                                            <div className="row_item">
                                                Всего
                                            </div>
                                            <div className="row_item">
                                                Выдано
                                            </div>
                                            <div className="row_item">
                                                На складе
                                            </div>
                                            <div className="row_item">
                                                Отменено
                                            </div>
                                            <div className="row_item">
                                                Сумма
                                            </div>
                                            <div className="row_item">
                                                Срок поставки
                                            </div>
                                            <div className="row_item">
                                                Статус
                                            </div>
                                            <div className="row_item">
                                                Комментарий
                                            </div>
                                            <div className="row_item">
                                                Заказ
                                            </div>
                                            <div className="row_item">
                                                Номер выдачи заказа
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    {data &&
                                        data.results &&
                                        data.results.map((el, i) => {
                                            const currentDate = moment(
                                                el.date
                                            ).format("DD.MM.YYYY HH:mm");
                                            let prevTime = currentDate;

                                            if (i != 0) {
                                                prevTime = moment(
                                                    data.results[i - 1].date
                                                ).format("DD.MM.YYYY HH:mm");
                                            }
                                            let showDate = 0;
                                            if (currentDate !== prevTime)
                                                showDate = i;

                                            return (
                                                <>
                                                    {showDate === i ? (
                                                        <div className="time_separator">
                                                            {moment(
                                                                el.date
                                                            ).format(
                                                                "DD.MM.YYYY HH:mm"
                                                            )}
                                                        </div>
                                                    ) : null}
                                                    <OrderItem
                                                        item={el}
                                                        key={el.id}
                                                    />
                                                </>
                                            );
                                        })}
                                </div>
                                <div>
                                    {data && data.count > 100 ? (
                                        <Pagination
                                            current={activePage}
                                            pageCount={100}
                                            total={data.count}
                                            onChange={this.changePage}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="no_item_orders">
                            <div className="box_img">
                                <img src={NoItems} alt="" />
                            </div>
                            <div className="title_no_item">
                                Пока нет ни одного заказа
                            </div>
                            <div className="descriptions_no_item">
                                После добавления первого заказа Вы увидите его
                                здесь
                            </div>
                        </div>
                    )}
                </div>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({ purchases }) => {
    return {
        data: purchases.data,
    };
};

const mapDispatchToProps = {
    getMyPurchases,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderItems);
